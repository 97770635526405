body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/roboto-v27-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-v27-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v27-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v27-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v27-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v27-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/roboto-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v27-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/roboto-v27-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../fonts/roboto-v27-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/roboto-v27-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/roboto-v27-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/roboto-v27-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/roboto-v27-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}